import {getCustomer} from './../../api/customer/index'
import Watermark from './../../assets/watermark'
import store from './../../store/index'
import {getAuthRouters, getPathVisitTop, getProcessHisApply, getProcessHisDealt} from "../../api/customer";
import {getMenuList} from "../../api/newApi";
import {checkPermi} from "../../css/Sugar";
import {Toast} from "vant";

const name = 'index';

const data = function () {
    return  {
        conheight:{
            height:''
        },
        conheight3:{
            height:''
        },
        tabList:[
            {label:'全部',content:'全部'},
            // {label:'审批',content:'审批'},
            {label:'CRM',content:'CRM'},
            {label:'渠道',content:'渠道'},
            {label:'审批',content:'审批'},
            {label:'营销',content:'营销'},
            {label:'财务',content:'财务'},
            {label:'人力',content:'人力'},
            {label:'仓库',content:'仓库'},
            {label:'统计',content:'统计'},
        ],
        routeList:[
            // {name:'审批',children:[
            //         {name:'我的发起',path:'/approval/launch?tagType=1',isShow:true,icon:'gold-coin-o',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/6a386e9b-1899-4210-9acd-4ad977808cc3.png'},
            //         {name:'我的待办',path:'/approval/launch?tagType=2',isShow:true,icon:'gold-coin-o',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/0d2397a4-f183-430a-b971-2f6bf2de278b.png'},
            //         {name:'我的已办',path:'/approval/launch?tagType=3',isShow:true,icon:'gold-coin-o',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/0c620492-b4d3-42f3-aa88-54de1c1e070d.png'},
            //     ]},
            {
                name:'CRM',children:[
                    {name:'客户管理',path:'/customerManage',isShow:true,icon:'manager',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/08626977-ab2b-4c1f-b9de-c0adc262e1e2.png'},
                    {name:'客户公海',path:'/customer/seas',isShow:true,icon:'map-marked',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/bb490dd5-3c9e-41c6-844b-476680f19df2.png'},
                    {name:'线索公海',path:'/customer/clue-seas',isShow:true,icon:'map-marked',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/b7ad2f2a-cd71-489a-b536-63418860cf2f.png'},
                    {name:'我的线索',path:'/customer/clue',isShow:true,icon:'info',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/c0a3b444-7765-40df-bbb9-b8c5f3daeec8.png'},
                    {name:'跟进记录',path:'/follower-Record',isShow:true,icon:'column',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/41e9edfa-f147-49d1-81e3-bd9506240523.png'},
                    {name:'预报列表',path:'/order',isShow:true,icon:'completed',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/f5b1d6b5-2794-400f-a01d-755b8899cd2a.png'},
                    {name:'新增预报',path:'/save',isShow:true,icon:'completed',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/9892c228-3c76-4df5-b1bc-385f7ce6b4c4.png'},
                    {name:'外出跟进',path:'/customer/follower/out',isShow:true,icon:'column',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/41e9edfa-f147-49d1-81e3-bd9506240523.png'},
                ]
            },
            {name:'营销',children:[

                ]},
            {name:'财务',children:[
                    {name:'订单测算管理',path:'/ordercalculate',isShow:true,icon:'gold-coin-o',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/270de0d0-93fa-48fc-a994-bc231d5b222d.png'},
                    {name:'审批单管理',path:'/auditlist',isShow:true,icon:'notes-o',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/270de0d0-93fa-48fc-a994-bc231d5b222d.png'},
                ]},
            {name:'人力',children:[
                    {name:'绩效考核表',path:'/kpi',isShow:true,icon:'manager-o',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/fc605307-b060-461b-abce-c8f72050590c.png'},
                ]},
            {name:'仓库',children:[
                    {name:'入库单',path:'/housing',isShow:true,icon:'logistics',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/b009836b-e0ef-4249-b433-9eecd85fe6d1.png'},
                    {name:'装柜单',path:'/container',isShow:true,icon:'logistics',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/dc3045b4-f406-4061-b963-062c59601e53.png'},
                    {name:'入库查验',path:'/housing/check',isShow:true,icon:'logistics',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/0dceeb42-1e70-4642-a4d8-3b17942c41df.png'},
                ]},
            {name:'统计',children:[
                    {name:'预报渠道',path:'/predictionsummary',isShow:true,icon:'gold-coin-o',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/c067778c-7003-49aa-813f-2848065ac62c.png'},
                    {name:'预报客户',path:'/predictiondetail',isShow:true,icon:'notes-o',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/c067778c-7003-49aa-813f-2848065ac62c.png'},
                    {name:'预报营销',path:'/reportpredictionsales',isShow:true,icon:'notes-o',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/c067778c-7003-49aa-813f-2848065ac62c.png'},
                    {name:'客户到仓',path:'/predictionarrive',isShow:true,icon:'notes-o',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/c067778c-7003-49aa-813f-2848065ac62c.png'},
                    {name:'龙虎榜',path:'/dragontger',icon:'medal',isShow:true,img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/b29273d7-4f16-4c59-9f56-f45c61f5bb67.png'},
                    {name:'业务货量',path:'/salesorderachieve',isShow:true,icon:'notes-o',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/c067778c-7003-49aa-813f-2848065ac62c.png'},
                ]},
        ],
        weekOption:['一','二','三','四','五','六','日'],
        currentDay:new Date().getDay(),
        weekDataList:[],
        activeName:'首页',
        processOneList:[],
        processTwoList:[],
        proc_inst_sts:{
            ACTIVE:'运行',
            COMPLETED:'结束',
            EXTERNALLY_TERMINATED:'关闭'
        },
        commonlyMenu:[],
        userForm:{},
        permissions:[],
        menuList:[]
    }
};

const methods = {
    getHeight(){
        this.conheight.height=window.innerHeight-50+'px';
        this.conheight3.height=window.innerHeight-300+'px';
    },

    getKF(){
        Toast({message: '模块开发中，敬请期待', forbidClick: true, loadingType: 'spinner',duration:1500});
    },

    testClick(){
        this.$router.push({ path: '/customerManage'});
    },

    cwOneClick(){
        this.$router.push({ path: '/ordercalculate'});
    },

    cwTwoClick(){
        this.$router.push({ path: '/auditlist'});
    },

    crmThreeClick(){
        this.$router.push({path:'/order'})
    },

    crmTwoClick(){
        this.$router.push({path:'/followerRecord'})
    },

    routerClick(path){
        this.$router.push({path:path});
    },

    getWeekDataList(){
        var weekList = [];
        var date = new Date();
        //判断本日期是否为周日，获取本周一日期
        if(date.getDay()=="0"){
            date.setDate(date.getDate() -6);
        }else {
            date.setDate(date.getDate() - date.getDay() + 1);
        }
        var myDate=date.getDate();
        var myMonth=date.getMonth() + 1;
        if(date.getDate()<10){
            myDate= '0'+ myDate;
        }
        if(date.getMonth() + 1<10){
            myMonth='0'+myMonth;
        }
        weekList.push(date.getFullYear() + "-" + myMonth+ "-" + myDate);
        // 获取周二以后日期
        for(var i=0;i<6;i++) {
            date.setDate(date.getDate() + 1);
            myDate=date.getDate();
            myMonth=date.getMonth() + 1;
            if(date.getDate()<10){
                myDate= '0'+ myDate;
            }
            if(date.getMonth() + 1<10){
                myMonth='0'+myMonth;
            }
            weekList.push(date.getFullYear() + "-" + myMonth+ "-" +myDate);
        }
        this.weekDataList = weekList;
        return weekList
    },

    weekClick(item){
        this.currentDay = item;
    },

    getActive(name){
        this.activeName = name;
    },

    getProcessOne(){
        getProcessHisDealt({pageNum:1,pageSize:10})
            .then(resp => {
                this.processOneList = resp.data.records;
            })
    },

    getProcessTwo(){
        getProcessHisApply({pageNum:1,pageSize:10})
            .then(resp => {
                this.processTwoList = resp.data.records;
            })
    },

    arrayObjectReduction(list,name){
        var result = [];
        var obj = {};
        for(var i =0; i<list.length; i++){
            if(!obj[list[i][name]]){
                result.push(list[i]);
                obj[list[i][name]] = true;
            }
        }
        return result;
    },

    getAuth(){
        getMenuList()
            .then(resp => {
                this.userForm = resp.data.user;
                this.permissions = resp.data.permissions;
                localStorage.setItem('permissions',resp.data.permissions.join(','));
                getAuthRouters()
                    .then(res =>{
                        this.traverseArray(res.data);
                        this.routeList[1].children = [
                            {name:'专线订单(客服)',path:'/market/orderLine?type='+((this.menuList.indexOf('oms/saleBill/order/index')!==-1)?'kf':(this.menuList.indexOf('oms/saleBill/bus/index')!==-1?'yw':'')),
                                isShow:(checkPermi(['oms:ozorder:list']))&&(this.menuList.indexOf('oms/saleBill/order/index')!==-1||this.menuList.indexOf('oms/saleBill/bus/index')!==-1),
                                icon:'gold-coin-o',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/f4ea536f-b2c7-4cb0-89d2-f390ec600ef7.png'},
                            {name:'专线订单(业务)',path:'/market/orderLine?type='+((this.menuList.indexOf('oms/saleBill/bus/index')!==-1)?'yw':(this.menuList.indexOf('oms/saleBill/order/index')!==-1?'kf':'')),
                                isShow:(checkPermi(['oms:ozorder:list']))&&(this.menuList.indexOf('oms/saleBill/bus/index')!==-1||this.menuList.indexOf('oms/saleBill/order/index')!==-1),
                                icon:'gold-coin-o',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/f4ea536f-b2c7-4cb0-89d2-f390ec600ef7.png'},
                            {name:'传统订单',path:'/market/order?type='+((this.menuList.indexOf('oms/saleBill/order/index')!==-1)?'kf':(this.menuList.indexOf('oms/saleBill/bus/index')!==-1?'yw':'')),
                                isShow:(checkPermi(['order:ocorder:list']))&&(this.menuList.indexOf('oms/saleBill/order/index')!==-1||this.menuList.indexOf('oms/saleBill/bus/index')!==-1),
                                icon:'gold-coin-o',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/f4ea536f-b2c7-4cb0-89d2-f390ec600ef7.png'},
                            {name:'整票订单',path:'/market/orderZG?type='+((this.menuList.indexOf('oms/saleBill/order/index')!==-1)?'kf':(this.menuList.indexOf('oms/saleBill/bus/index')!==-1?'yw':'')),
                                isShow:(checkPermi(['order:osorder:list']))&&(this.menuList.indexOf('oms/saleBill/order/index')!==-1||this.menuList.indexOf('oms/saleBill/bus/index')!==-1),
                                icon:'gold-coin-o',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/f4ea536f-b2c7-4cb0-89d2-f390ec600ef7.png'},
                            {name:'LDP订单',path:'/market/orderLDP?type='+((this.menuList.indexOf('oms/saleBill/order/index')!==-1)?'kf':(this.menuList.indexOf('oms/saleBill/bus/index')!==-1?'yw':'')),
                                isShow:(checkPermi(['order:ldporder:list']))&&(this.menuList.indexOf('oms/saleBill/order/index')!==-1||this.menuList.indexOf('oms/saleBill/bus/index')!==-1),
                                icon:'gold-coin-o',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/f4ea536f-b2c7-4cb0-89d2-f390ec600ef7.png'},
                            {name:'关务询盘',path:'/market/customs/index', isShow:checkPermi(['oms:market:customs:index']),
                                icon:'gold-coin-o',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/aa5c5df6-37e3-4b88-9399-1995f8e1d0e5.png'},
                            {name:'新建关务',path:'/market/customs/add', isShow:checkPermi(['oms:market:customs:index']),
                                icon:'gold-coin-o',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/e7581c22-06e4-4183-8467-e94060a177be.png'},
                        ];
                        this.routeList[4].children.push(
                            {name:'关联配置',path:'/market/warehouse/goodsconfig',isShow:this.menuList.indexOf('oms/warehouse/goodsfba/index')!==-1,icon:'logistics',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/ff3593d6-e164-4833-9d57-519519f2090b.png'},
                            {name:'关联记录',path:'/market/warehouse/goodsrecord',isShow:this.menuList.indexOf('oms/warehouse/goodsfba/index')!==-1,icon:'logistics',img:"https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/30c38994-d0f3-4c07-b898-17802f362880.png"},
                        );
                        this.commonlyMenu = [
                            {name:'专线订单(客服)',path:'/market/orderLine?type='+((this.menuList.indexOf('oms/saleBill/order/index')!==-1)?'kf':(this.menuList.indexOf('oms/saleBill/bus/index')!==-1?'yw':'')),
                                isShow:(checkPermi(['oms:ozorder:list']))&&(this.menuList.indexOf('oms/saleBill/order/index')!==-1||this.menuList.indexOf('oms/saleBill/bus/index')!==-1),
                                icon:'gold-coin-o',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/f4ea536f-b2c7-4cb0-89d2-f390ec600ef7.png'},
                            {name:'专线订单(业务)',path:'/market/orderLine?type='+((this.menuList.indexOf('oms/saleBill/bus/index')!==-1)?'yw':(this.menuList.indexOf('oms/saleBill/order/index')!==-1?'kf':'')),
                                isShow:(checkPermi(['oms:ozorder:list']))&&(this.menuList.indexOf('oms/saleBill/bus/index')!==-1||this.menuList.indexOf('oms/saleBill/order/index')!==-1),
                                icon:'gold-coin-o',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/f4ea536f-b2c7-4cb0-89d2-f390ec600ef7.png'},
                            {name:'传统订单',path:'/market/order?type='+((this.menuList.indexOf('oms/saleBill/order/index')!==-1)?'kf':(this.menuList.indexOf('oms/saleBill/bus/index')!==-1?'yw':'')),
                                isShow:(checkPermi(['order:ocorder:list']))&&(this.menuList.indexOf('oms/saleBill/order/index')!==-1||this.menuList.indexOf('oms/saleBill/bus/index')!==-1),
                                icon:'gold-coin-o',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/f4ea536f-b2c7-4cb0-89d2-f390ec600ef7.png'},
                            {name:'整票订单',path:'/market/orderZG?type='+((this.menuList.indexOf('oms/saleBill/order/index')!==-1)?'kf':(this.menuList.indexOf('oms/saleBill/bus/index')!==-1?'yw':'')),
                                isShow:(checkPermi(['order:osorder:list']))&&(this.menuList.indexOf('oms/saleBill/order/index')!==-1||this.menuList.indexOf('oms/saleBill/bus/index')!==-1),
                                icon:'gold-coin-o',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/f4ea536f-b2c7-4cb0-89d2-f390ec600ef7.png'},
                            {name:'LDP订单',path:'/market/orderLDP?type='+((this.menuList.indexOf('oms/saleBill/order/index')!==-1)?'kf':(this.menuList.indexOf('oms/saleBill/bus/index')!==-1?'yw':'')),
                                isShow:(checkPermi(['order:ldporder:list']))&&(this.menuList.indexOf('oms/saleBill/order/index')!==-1||this.menuList.indexOf('oms/saleBill/bus/index')!==-1),
                                icon:'gold-coin-o',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/f4ea536f-b2c7-4cb0-89d2-f390ec600ef7.png'},
                            {name:'客户管理',path:'/customerManage',isShow:true,icon:'manager',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/08626977-ab2b-4c1f-b9de-c0adc262e1e2.png'},
                            {name:'预报列表',path:'/order',icon:'completed',isShow:true,img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/f5b1d6b5-2794-400f-a01d-755b8899cd2a.png'},
                            {name:'新增预报',path:'/save',icon:'completed',isShow:true,img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/9892c228-3c76-4df5-b1bc-385f7ce6b4c4.png'},
                            {name:'入库单',path:'/housing',icon:'logistics',isShow:true,img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/b009836b-e0ef-4249-b433-9eecd85fe6d1.png'},
                            {name:'绩效考核表',path:'/kpi',icon:'manager-o',isShow:true,img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/fc605307-b060-461b-abce-c8f72050590c.png'},
                            {name:'审批单管理',path:'/auditlist',icon:'notes-o',isShow:true,img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/270de0d0-93fa-48fc-a994-bc231d5b222d.png'},
                            {name:'我的线索',path:'/customer/clue',icon:'info',isShow:true,img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/c0a3b444-7765-40df-bbb9-b8c5f3daeec8.png'},
                            {name:'龙虎榜',path:'/dragontger',icon:'medal',isShow:true,img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/b29273d7-4f16-4c59-9f56-f45c61f5bb67.png'},
                            // {name:'我的发起',path:'/approval/launch?tagType=1',icon:'gold-coin-o',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/6a386e9b-1899-4210-9acd-4ad977808cc3.png'},
                            // {name:'我的待办',path:'/approval/launch?tagType=2',icon:'gold-coin-o',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/0d2397a4-f183-430a-b971-2f6bf2de278b.png'},
                            // {name:'我的已办',path:'/approval/launch?tagType=3',icon:'gold-coin-o',img:'https://hubologistics-bs.oss-cn-shenzhen.aliyuncs.com/hubo/0c620492-b4d3-42f3-aa88-54de1c1e070d.png'},
                        ];
                        getPathVisitTop({clientType:1,n:15})
                            .then(response => {
                                var list = [];
                                response.data.map((shit) => {
                                    if(shit!=='/'){
                                        this.routeList.map((ff) => {
                                            ff.children.map((cc) => {
                                                if(cc.path.indexOf(shit)!==-1){
                                                    list.push(cc);
                                                }
                                            })
                                        })
                                    }
                                })
                                var arrList = this.arrayObjectReduction(list,'path');
                                if(arrList.length>0){
                                    this.commonlyMenu = arrList
                                }
                            })
                    })
            })
    },

    //递归遍历数组对象-children
    traverseArray(arr){
        arr.forEach(item => {
            this.menuList.push(item.component);
            if (item.children && item.children.length) {
                this.traverseArray(item.children); // 递归遍历子元素
            }
        });
    }
}

function mounted() {
    // if(sessionStorage.getItem("userName")===null){
    //     this.$router.push("/login")
    // }
    if(!localStorage.getItem('token')){
        this.$router.push({path:"/login"});
    }
    Watermark.set(localStorage.getItem("userName")+"@巨宝物流跨境易")
    this.getWeekDataList();
    this.getProcessOne();
    this.getProcessTwo();
    this.getAuth();
}


export default {
    name: name,

    data: data,

    methods: methods,

    mounted: mounted,

    created(){
        window.addEventListener('resize', this.getHeight);
        this.getHeight()
    },

};