<template>
  <div class="order-page">
    <van-nav-bar class="title">
      <template #left>
        <van-icon name="arrow-left" @click="goBack" size="12" />
      </template>
      <div slot="title">
        <span>预报列表</span>
      </div>
      <div class="btn-list"
           slot="right">
        <!--<span @click="handleAddrecord">新增跟进记录</span>-->
        <span @click="handleReLogin">更新客户</span>
        <span @click="handleAdd">新增</span>
        <span v-if="isChange"
              @click="handleReset">重置</span>
        <span @click="handleSearch">查询</span>
      </div>
    </van-nav-bar>
    <div class="search-row">
      <v-text-field v-model="form.prdName"
                    hide-details
                    placeholder="渠道名称"></v-text-field>
      <v-text-field v-model="form.custName"
                    hide-details
                    placeholder="客户名称"></v-text-field>
      <v-text-field v-model="form.warehouseCode"
                    hide-details
                    placeholder="仓库代码或邮编"></v-text-field>
      <v-select v-model="form.deliverywareCode"
                :items="warehouseList"
                hide-details
                placeholder="交货仓库"></v-select>
      <v-menu v-model="dateMenuShow"
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="form.deliverywareDate"
                        hide-details
                        placeholder="填报日期"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on">
          </v-text-field>
        </template>
        <v-date-picker v-model="form.deliverywareDate"
                       :first-day-of-week="1"
                       :day-format="dayFormat"
                       no-title
                       scrollable
                       @input="dateMenuShow = false">
        </v-date-picker>
      </v-menu>
      <v-select v-model="form.status"
                :menu-props="{ auto: true, offsetY: true, offsetX: false, }"
                :items="statusList"
                hide-details
                placeholder="状态"></v-select>
    </div>
    <van-list class="list"
              v-if="list.length > 0"
              v-model="loading"
              :finished="finished"
              @load="handleLoad">
      <order-item v-for="item in list"
                  :info="item"
                  @cancel="handleCancel"
                  @confirmCancel="handleConfirmCancel"
                  @confirmOrder="handleConfirmOrder"
                  :key="item.id" />
    </van-list>
    <van-empty class="empty-image"
               v-if="list.length === 0"
               :description="desc" >
      <van-loading v-if="dataFT" type="spinner" />
    </van-empty>
    <v-dialog v-model="cancelDialog"
              max-width="290">
      <v-card>
        <v-card-title>
          是否确认取消预报
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="grey"
                 text
                 @click="handleClose">
            取消
          </v-btn>

          <v-btn color="deep-orange  darken-1"
                 :loading="cancelLoading"
                 text
                 @click="setCancel">
            确认
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmDialog"
              max-width="290">
      <v-card>
        <v-card-title>
          是否确认预报
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey"
                 text
                 @click="confirmDialog = false">
            取消
          </v-btn>

          <v-btn color="deep-orange  darken-1"
                 :loading="confirmLoading"
                 text
                 @click="handleConfirm">
            确认
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import {
  getWarehouseList,
} from "@/api/api.js";
import {
  getPredictionList,
  cancel,
  cancelConfirm,
  predictionConfirm,
  getChannelList,
} from "@/api/newApi.js";
// import { baseUrl } from "@/api/baseUrl.js";
import OrderItem from "./components/order-item";
import {selecttransit} from "../../api/customer";
export default {
  name: "order-page",
  components: {
    OrderItem
  },
  data () {
    return {
      loading: true,
      animated: false,
      ruleDialog: false,
        desc:"数据加载中...",
        dataFT:true,
      list: [],
      originalFrom: {
        prdName: "", // 频道名称
        custName: "", // 客户名称
        warehouseCode: "", // 仓库代码或邮编
        deliverywareCode: "", // 交货仓库
        status: "", // 状态
        deliverywareDate: "", // 填报日期
      },
      form: {},
      dateMenuShow: false, // 日期选择器是否展示
      warehouseList: [],//  仓库
      channelList: [],
      statusList: [
        {
          text: "全部",
          value: "",
        },
        {
          text: "预报",
          value: "0",
        },
        {
          text: "取消",
          value: "1",
        },
        {
          text: "截止",
          value: "2",
        },
        {
          text: "已取消",
          value: "3",
        },
        {
          text: "已确认",
          value: "4",
        },
      ],
      cancelLoading: false,
      cancelDialog: false,
      cancelStatus: "1", // 取消状态
      currentInfo: {},
      finished: false,
      pageSize: 10,
      pageNum: 1,
      confirm: false, // 确认取消
      confirmDialog: false, // 确认弹窗
      confirmLoading: false, // 确认弹窗loading
    }
  },
  computed: {
    isChange () {
      return JSON.stringify(this.form) !== JSON.stringify(this.originalFrom)
    }
  },
  mounted () {
    this.getWarehouseList();
    this.getChannelList();
    this.getPredictionList();
    this.form = { ...this.originalFrom }
  },
  methods: {
    handleSearch () {
      this.pageNum = 1;
      this.list = [];
      this.getPredictionList();
    },
      goBack(){
          this.$router.back();
      },

      handleLoad () {
      this.pageNum += 1;
      this.getPredictionList();
    },
    handleReset () {
      this.pageNum = 1;
      this.form = { ...this.originalFrom }
      this.handleSearch();
    },
    handleAdd () {
      this.$router.push("/save")
    },
    handleAddrecord () {
      this.$router.push("/followrecord")
    },
    handleBack () {
      this.$router.back();
    },
    dayFormat (str) {
      str = str.slice(-2);
      return Number(str)
    },
    handleCancel (info) {
      this.currentInfo = { ...info };
      this.cancelDialog = true;
      this.cancelStatus = "1"
    },
    handleConfirmCancel (info) {
      this.currentInfo = { ...info };
      this.cancelDialog = true;
      this.cancelStatus = "3"
    },
    // 触发确认预报
    handleConfirmOrder (info) {
      this.currentInfo = { ...info };
      this.confirmDialog = true;
    },
    handleClose () {
      if (this.cancelStatus == "3") {
        this.cancelStatus = "0"
        this.setCancel();
      } else {
        this.cancelDialog = false;
      }
    },
    // 重新登录
    handleReLogin () {
      this.$store.dispatch("login")
    },
    // 确认预报
    handleConfirm () {
      this.confirmLoading = true;
      let form = {
        id: this.currentInfo.id,
      }
      predictionConfirm(form).then((res) => {
        this.$toast("确认成功")
        this.currentInfo = {};
        this.confirmLoading = false;
        this.confirmDialog = false;
        this.handleReset();
      }).catch((err) => {
        this.confirmLoading = false;
        console.log(err);
        this.$toast(err?.msg || "确认失败!请重试")
      });
    },
    setCancel () {
      let form = {
        id: this.currentInfo.id,
      }
      let fn = cancel;
      if (this.cancelStatus == "3" || this.cancelStatus == "0") {
        fn = cancelConfirm;
        form.confirm = this.cancelStatus == "3" ? true : false;
      }
      this.cancelLoading = true;
      fn(form).then((res) => {
        if (this.cancelStatus == "0") {
          this.$toast("驳回成功")
        } else {
          this.$toast("取消成功")
        }
        this.cancelLoading = false;
        this.cancelDialog = false;
        this.handleReset();
      }).catch((err) => {
        this.cancelLoading = false;
        console.log(err);
        this.$toast(err?.msg || "保存失败!请重试")
      });
    },
    // 获取仓库
    getWarehouseList () {
      // this.loading = true;
      // let param = {
      // }
      // getWarehouseList(param).then((res) => {
      //   let list = res.result || [];
      //   list = list.map(item => {
      //     return {
      //       text: item.name,
      //       value: item.name,
      //     }
      //   })
      //   this.warehouseList = list;
      //   this.loading = false;
      // }).catch((err) => {
      //   console.log(err);
      //   let msg = err.msg || "网络异常"
      //   this.$toast(msg)
      //   this.loading = false;
      // });
        selecttransit()
            .then(resp => {
                let list = resp.data || [];
                list = list.map(item => {
                    return {
                        text: item.name,
                        value: item.name,
                    }
                })
                this.warehouseList = list;
            })
    },
    // 获取渠道
    getChannelList () {
      let param = {
      }
      getChannelList(param).then((res) => {
        let list = res.data || [];
        this.channelList = list;
        this.loading = false;
      }).catch((err) => {
        console.log(err);
        let msg = err.msg || "网络异常"
        this.$toast(msg)
        this.loading = false;
      });
    },
      formatTime(timestamps, format){
          var date = new Date(timestamps);
          var finalstr=format;
          finalstr=finalstr.replace('yyyy', date.getFullYear());//年
          finalstr=finalstr.replace('mm', this.formatNum(date.getMonth() + 1));//月
          finalstr=finalstr.replace('dd', this.formatNum(date.getDate()));//天
          finalstr=finalstr.replace('hh',this.formatNum(date.getHours()));//时
          finalstr=finalstr.replace('mi', this.formatNum(date.getMinutes()));//分
          finalstr=finalstr.replace('ss', this.formatNum(date.getSeconds()));//秒
          finalstr=finalstr.replace('SSS', this.formatMilliseconds(date.getMilliseconds()));//毫秒
          //如果不想返回秒和毫秒，注释掉相应行数，传入参数时去掉该参数
          return finalstr;
      },
      formatNum(arg0){
          let str = arg0.toString();
          if (str.length == 1) {
              return "0" + str;
          } else {
              return str;
          }
      },
      formatMilliseconds(arg){
          var str = arg.toString();
          if (str.length == 1) {
              return "00" + str;
          } else if (str.length == 2) {
              return "0" + str;
          } else if (str.length == 3) {
              return str;
          }
      },
    // 获取预报列表
    getPredictionList () {
      let param = {
        ...this.form,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
          status:this.form.status,
          beginCreatetime:this.formatTime(new Date().getTime()-(90*24*60*60*1000),'yyyy-mm-dd'),
          endCreatetime:this.formatTime(new Date().getTime(),'yyyy-mm-dd')
      }
      this.loading = true;
      getPredictionList(param).then((res) => {
          if(res.data.records.length===0){
              this.dataFT = false;
              this.desc = "无数据"
          }
        let { records, pages, current } = res.data;
        let list = records || [];
        if (pages <= current) {
          this.finished = true;
        }
        if (this.pageNum == 1) {
          this.list = list;
        } else {
          this.list = this.list.concat(list);
        }
        this.loading = false;
      }).catch((err) => {
        console.log(err);
        let msg = err.msg || "网络异常"
        this.$toast(msg)
        this.finished = true;
        this.loading = false;
      });

    },

  }
}
</script>

<style lang="scss" scoped>
:deep(.v-input__slot) {
  &::before {
    display: none;
  }

  &::after {
  }
}

:deep(.v-text-field) {
  padding-top: 0;
}

:deep(.v-list-item__title) {
  font-size: 0.56rem;
}

.order-page {
  position: relative;
  // width:100%;
  height: 100vh;
  background-color: #f5f6fa;
  overflow: hidden;
  overflow-y: auto;
  padding-top: 46px;
  font-size: 0.64rem;
  box-sizing: border-box;
  z-index: 1;

  > div:not(.van-popup, .van-nav-bar) {
    position: relative;
    z-index: 1;
  }
}

.v-picker {
  font-size: 0.56rem;
}

// 标题
.title {
  text-align: left;

  &::after {
    display: none;
  }

  :deep(.van-nav-bar__title) {
    margin: 0;
    margin-left: 40px;
    font-weight: normal;
    font-size: 16px;

    > div {
      display: flex;
      align-items: center;
    }
  }

  :deep(.van-nav-bar__right:active) {
    opacity: 1;
  }

  .btn-list {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    span {
      padding: 0.01rem 0.2rem;
      font-size: 12px;
      color: dodgerblue;
      transition: all 0.3;

      &:active {
        opacity: 0.7;
      }
    }
  }
}

.search-row {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  padding: 0.16rem 0.64rem;
  background-color: #fff;
  border-top: 1px solid #f2f2f2;
  margin-bottom: 0.64rem;

  .primary--text {
    color: #e8ecff !important;
  }

  .v-input {
    flex: none;
    width: 48%;
    max-width: 240px;
    font-size: 0.56rem;
    border-bottom: 1px solid #f2f2f2;
    color: #fcfcfe;

    input {
      max-height: 1.28rem;
    }

    :deep(.v-input__slot:after) {
      border-width: 0.6px;
    }
  }
}

.empty-image {
  padding-top: 2.56rem;
  margin: 0 auto;
}

.list {
  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap;
}
</style>