const tokenKey = "token"

export function getToken() {
  return localStorage.getItem(tokenKey)
}

export function setToken(token) {
  return localStorage.setItem(tokenKey, token)
}
export function removeToken() {
  try {
    return localStorage.removeItem(tokenKey)
  } catch (e) {
    return true
  }
}

export function getKey() {
  return tokenKey;
}
//name 字段名   value 字段值   perpetual  有效期
export function setCookie(name, value, perpetual = 3) {
  let exdate = new Date()
  exdate.setDate(exdate.getDate() + perpetual) //exdate.setDate(exdate.getDate() + 365)
  document.cookie = name + '="' + value + '";expires=' + exdate.toGMTString() + ";domain=.17of.com;path=/"
  //永久有效
  //document.cookie = name + '=' + value + ';expires=' + 'Fri, 31 Dec 9999 23:59:59 GMT'    
}