
import echarts from "echarts";
import "echarts/theme/macarons.js";

export function checkPermi(value) {
    if (value && value instanceof Array && value.length > 0) {
        const permissions = localStorage.getItem('permissions').split(',');
        const permissionDatas = value
        const all_permission = "*:*:*";

        const hasPermission = permissions.some(permission => {
            return all_permission === permission || permissionDatas.includes(permission)
        })

        if (!hasPermission) {
            return false
        }
        return true
    } else {
        console.error(`need roles! Like checkPermi="['system:user:add','system:user:edit']"`)
        return false
    }
}
/*
el-Message提示框
 */
// export function elMessage(msg, type) {
//     return Message({
//         showClose: true,
//         message: msg,
//         type: type
//     });
// }

/*
生成随机数
 */
export function getRandom(value) {
    return Math.round(Math.random() * value);
}

/*
获取输入天日期(格式化yyyy-mm-dd)
 */
export function getDateForDay(day) {
    var dates = [];
    var days = [];
    for (var d = 1; d < day + 1; d++) {
        days.push(d - day);
    }
    var date1 = new Date(),
        // eslint-disable-next-line no-unused-vars
        time1 =
            date1.getFullYear() +
            "-" +
            (date1.getMonth() + 1) +
            "-" +
            date1.getDate(); //time1表示当前时间
    for (var i = 0; i < days.length; i++) {
        var date2 = new Date(date1);
        date2.setDate(date1.getDate() + days[i]);
        dates.push(
            date2.getFullYear() +
            "-" +
            (date2.getMonth() + 1 < 10
                ? "0" + (date2.getMonth() + 1)
                : date2.getMonth() + 1) +
            "-" +
            (date2.getDate() < 10 ? "0" + date2.getDate() : date2.getDate())
        );
    }
    return dates;
}

/*
获取输入日期范围
 */
export function getDateDiffDay(day, end_date, start_time_hms, end_time_hms) {
    var dates = [];
    var days = [];
    for (var d = 1; d < day + 1; d++) {
        days.push(d - day);
    }
    var date1 = new Date(end_date),
        // eslint-disable-next-line no-unused-vars
        time1 =
            date1.getFullYear() +
            "-" +
            (date1.getMonth() + 1) +
            "-" +
            date1.getDate(); //time1表示当前时间
    for (var i = 0; i < days.length; i++) {
        var date2 = new Date(date1);
        date2.setDate(date1.getDate() + days[i]);
        if (i > 0 && i < days.length - 1) {
            dates.push(
                date2.getFullYear() +
                "-" +
                (date2.getMonth() + 1 < 10
                    ? "0" + (date2.getMonth() + 1)
                    : date2.getMonth() + 1) +
                "-" +
                (date2.getDate() < 10 ? "0" + date2.getDate() : date2.getDate()) +
                " 00:00:00"
            );
        } else if (i == 0) {
            dates.push(
                date2.getFullYear() +
                "-" +
                (date2.getMonth() + 1 < 10
                    ? "0" + (date2.getMonth() + 1)
                    : date2.getMonth() + 1) +
                "-" +
                (date2.getDate() < 10 ? "0" + date2.getDate() : date2.getDate()) +
                " " +
                start_time_hms
            );
            if (days.length == 1) {
                dates.push(
                    date2.getFullYear() +
                    "-" +
                    (date2.getMonth() + 1 < 10
                        ? "0" + (date2.getMonth() + 1)
                        : date2.getMonth() + 1) +
                    "-" +
                    (date2.getDate() < 10 ? "0" + date2.getDate() : date2.getDate()) +
                    " " +
                    end_time_hms
                );
            }
        } else if (i == days.length - 1) {
            dates.push(
                date2.getFullYear() +
                "-" +
                (date2.getMonth() + 1 < 10
                    ? "0" + (date2.getMonth() + 1)
                    : date2.getMonth() + 1) +
                "-" +
                (date2.getDate() < 10 ? "0" + date2.getDate() : date2.getDate()) +
                " " +
                end_time_hms
            );
        }
    }
    return dates;
}

/*
获取过去一年的年月份(包括本月)
 */
export function getPreMonth(date, num) {
    var arr = date.split("-");
    var year = arr[0]; //获取当前日期的年份
    var month = arr[1]; //获取当前日期的月份
    // eslint-disable-next-line no-unused-vars
    var days = new Date(year, month, 0);
    days = days.getDate(); //获取当前日期中月的天数
    var year2 = year;
    var ym_datas = [];
    for (var i = 0; i < num; i++) {
        var month2 = parseInt(month) - i;
        if (month2 <= 0) {
            //如果是1月份，则取上一年的12月份
            year2 = parseInt(year) - 1;
            month2 = 12 + month2;
        }
        if (month2 < 10) {
            month2 = "0" + month2; //月份填补成2位。
        }
        var t2 = year2 + "-" + month2;
        ym_datas.push(t2);
    }
    return ym_datas.sort();
}

export function shortcutsValue() {
    return [
        {
        text: '昨天',
        value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
            return [start, end]
        },
    },
        {
            text: '上周',
            value: () => {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                return [start, end]
            },
        },
        {
            text: '上个月',
            value: () => {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                return [start, end]
            },
        },
        {
            text: '上一年',
            value: () => {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                return [start, end]
            },
        }]
}

export function echarts_line(myeharts,seriesDataList,xData) {
    const color = ['#3ab5ba',
        '#b4eaf9',
        '#65728f',
        '#01b65b',
        '#3DD1F9',
        '#FFAD05']; //2个以上的series就需要用到color数组
    const legend = {
        //data，就是取得每个series里面的name属性。
        orient: 'horizontal',
        icon: 'circle', //图例形状
        padding: 0,
        top: 10,
        right: 0,
        itemWidth: 10, //小圆点宽度
        itemHeight: 10, // 小圆点高度
        itemGap: 21, // 图例每项之间的间隔。[ default: 10 ]横向布局时为水平间隔，纵向布局时为纵向间隔。
        textStyle: {
            fontSize: 12,
            color: '#000000',
            fontWeight: '300'
        },
    };
    const tooltip = {
        show: true,
        trigger: 'axis',
        axisPointer: {
            type: 'shadow',
        },
        textStyle:{
            color:'#5a5a5a',
            fontSize:12,
            fontWeight: '300'
        },
        backgroundColor:'rgba(255,255,255,0.5)',
        borderColor:'rgba(255,255,255,0.5)'
    };
    let seriesData = seriesDataList;
    const commonConfigFn = (index) => {
        return {
            type: 'line',
            smooth: true,
            symbol: 'emptyCircle', //空心小圆点。线条小圆点形状
            symbolSize: 6, //小圆点大小，
            itemStyle: {
                //还是小圆点设置
            },

            label: {
                show: false, //不显示小圆点上的label文字
            },
            lineStyle: {
                width: 1, //线条设置

            },

            areaStyle: {
                //填充线条下面的面积区域颜色。（areaStyle只是锦上添花）
                opacity: 0.2,
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                        {
                            offset: 0,
                            color: color[index], // 上处的颜色
                        },
                        {
                            offset: 1,
                            color: 'transparent', // 下处的颜色
                        },
                    ],
                    global: false, // 缺省为 false
                },
            },
        };
    };
    seriesData = seriesData.map((item, index) => ({ ...item, ...commonConfigFn(index) }));
    var option = {
        backgroundColor:'rgba(255,255,255,0.5)',
        color,
        tooltip,
        legend,
        grid: {
            top: '15%',
            left: '4%',
            right: '10%',
            bottom: '0%',
            containLabel: true,
        },

        xAxis: {
            show: true, //显示x轴+x轴label文字
            type: 'category',
            boundaryGap: false, //从Y轴出发，这个false很好的
            axisLine: {
                show: true, //显示x坐标轴轴线
                lineStyle: {
                    color: 'rgba(255,255,255,.4)',
                },
            },
            axisTick: {
                show: false, //不显示x坐标1cm刻度
            },
            axisLabel: {
                color: '#000000', //x轴label文字颜色
                fontWeight: '300',
                fontSize:12,
            },
            splitLine: {
                show: false, //不显示grid竖向分割线
            },

            data: xData,
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                color: '#000000',
                fontWeight: '300',
                fontSize:12,
            },
            axisLine: {
                show: true,
                lineStyle: {
                    color: 'rgba(255,255,255,.4)',
                    fontWeight: '300',
                    fontSize:12,
                },
            },
            splitLine: {
                show: false, //不显示grid水平分割线
            },
        },

        series: seriesData,
    }
    myeharts.clear();
    myeharts.setOption(option, true);
}

export function echarts_cyun(myeharts,dataList) {
    // const list = [
    //     {
    //         value: '50',
    //         name: '华为',
    //         textStyle: {
    //             shadowBlur: 4,
    //             shadowColor: '#ECEFFF',
    //             shadowOffsetY: 14,
    //             color: '#73DDFF',
    //         },
    //     }, // 50
    //     { value: '30', name: 'VIVO' },
    //     { value: '29', name: 'OPPO' },
    //     { value: '28', name: 'HONOR' },
    //     { value: '27', name: '红米' },
    //     { value: '26', name: '小米' },
    //     { value: '25', name: '美图' },
    //     { value: '24', name: 'ONEPLUS' },
    //     { value: '23', name: '魅族' },
    //     { value: '22', name: '红手指' },
    //     { value: '21', name: 'SAMSUNG' },
    //     { value: '20', name: '金立' },
    //     { value: '16', name: 'BLACKBERRY' },
    //     { value: '15', name: '诺基亚' },
    //     { value: '14', name: '锤子' },
    //     { value: '13', name: '大疆' },
    //     { value: '12', name: '361' },
    //     { value: '11', name: '摩托罗拉' },
    //     { value: '10', name: '联想' },
    //     { value: '9', name: '玩家国度' },
    // ];
    const list = dataList;
    var option = {
        tooltip: {
            show: true,
            borderColor: '#fe9a8bb3',
            borderWidth: 1,
            padding: [10, 15, 10, 15],
            confine: true,
            backgroundColor: 'rgba(255, 255, 255, .9)',
            textStyle: {
                color: 'hotpink',
                lineHeight: 22
            },
            extraCssText: 'box-shadow: 0 4px 20px -4px rgba(199, 206, 215, .7);border-radius: 4px;'
        },
        series: [
            {
                type: 'wordCloud',
                shape: 'pentagon',
                left: 'center',
                top: 'center',
                width: '100%',
                height: '100%',
                right: null,
                bottom: null,
                sizeRange: [14, 50],
                rotationRange: [0, 0],
                rotationStep: 0,
                gridSize: 25,
                drawOutOfBound: false,
                layoutAnimation: true,
                textStyle: {
                    fontFamily: 'PingFangSC-Semibold',
                    fontWeight: 600,
                    color: function (params) {
                        let colors = ['#fe9a8bb3', '#fe9a8bb3', '#fe9a8b03', '#9E87FFb3', '#9E87FFb3', '#9E87FFb3', '#fe9a8bb3', '#fe9a8bb3', '#fe9a8bb3', '#73DDFF', '#58D5FF']
                        return colors[parseInt(Math.random() * 10)];
                    },
                },
                emphasis: {
                    focus: 'none',
                },
                data: list,
            },
        ],
    };
    myeharts.clear();
    myeharts.setOption(option, true);
}

export function echarts_pie(myeharts,data) {
    let chartData = data;
// 字段值对齐，不足补空格
    let angle = 0;//角度，用来做简单的动画效果的
    var option = {
        color: ['#1EE7E7', '#BEE5FB', '#00D68A', '#1890FF', '#564AF1'],
        tooltip: {
            confine: true,
        },
        legend: {
            selectedMode: true,
            orient: 'vertical',
            right: '0%',
            top: 'center',
            itemWidth: 16,
            itemHeight: 16,
            formatter(name) { // 该函数用于设置图例显示后的百分比
                let data = chartData
                let total = 0
                let value = 0
                data.forEach((item) => {
                    total += item.value
                    if (item.name == name) {
                        value = item.value
                    }
                })
                let p = ((value / total) * 100).toFixed(1) // 求出百分比
                return ['{a|' + `${name}` +'}{b|' + `${value}` + '}{b1|项}{c|' + `${p || '0'}` + '%}{c1|%' + '}']
            },
            textStyle: {
                fontSize: 10,
                color: '#000000',
                padding: [2, 0],
                rich: {
                    b: {
                        padding: [1, 2, 0,5],
                        verticalAlign: 'sub',
                        fontSize: 10,
                        color: '#000000'
                    },
                    b1: {
                        color: 'rgba(255, 255, 255, 0.65)',
                        fontSize: 10,
                    },
                    c: {
                        padding: [0, 2, 0, 0],
                        fontSize: 10,
                        color: '#000000'
                    },
                    c1: {
                        color: 'rgba(255, 255, 255, 0.65)'
                    },
                }
            }
        },
        graphic: {
            elements: [{
                type: 'image',
                z: 4,
                top: 'center',
            }],
        },
        series: [
            {
                name: '',
                type: 'pie',
                radius: ['40%', '45%'],
                center: ['45%', '60%'],
                left:'-30%',
                top:'-20%',
                hoverAnimation: false,
                avoidLabelOverlap: false,
                itemStyle: {
                    // borderRadius: 10,
                    borderColor: '#071649',
                    borderWidth: 8,
                    normal: {
                        //饼状图阴影，值越大阴影亮度越高
                        shadowBlur: 10,
                        shadowColor: '#62e3e1'
                    }
                },
                label: {
                    show: false,
                    position: 'center',
                },
                labelLine: {
                    show: false,
                },
                data: chartData,
            },

        ],
    };
        myeharts.clear();
        myeharts.setOption(option, true)
    // function draw() {
    //     angle = angle + 3;
    //     myeharts.clear();
    //     myeharts.setOption(option, true)
    // }
    //
    // setInterval(function () {
    //     //用setInterval做动画感觉有问题最好的方法就是代码内定位图片然后加css3动画效果
    //     draw()
    // }, 100000000);
}


export function echarts_line2(myeharts,y1,y2,y3,y4,xData,legend) {
    legend = ["理论学习", "宣讲授课", "交流学习", "我为群众办实事"];
    xData = ['一月','二月','三月','四月','五月','六月','七月','八月','九月','十月','十一月','十二月'];
    y1 = [400, 410, 350, 320, 280, 340, 360, 400, 420, 410, 420, 380];
        y2 = [350, 320, 260, 240, 220, 280, 300, 360, 340, 340, 340, 290];
        y3 = [200, 260, 200, 190, 180, 220, 260, 300, 280, 300, 280, 200];
        y4 = [21, 22, 15, 10, 14, 15, 20, 16, 6, 8, 16, 12];
    var colorArr = [{
        start: "rgba(2, 201, 254,",
        end: "rgba(17, 107, 243, 0.6)"
    },
        {
            start: "rgba(48, 209, 188,",
            end: "rgba(29, 152, 127, 0.6)"
        },
        {
            start: "rgba(205, 178, 73,",
            end: "rgba(160, 121, 60, 0.6)"
        },
        {
            color: "#EC6941"
        }
    ];
    var normalColor = "#DEEBFF";
    var borderData = [];
    let seriesData = [];
    var borderHeight = 4;
    xData.forEach(element => {
        borderData.push(borderHeight);
    });
    [y1, y2, y3, y4].forEach((item, index) => {
        var obj1 = {};
        if (index < 3) {
            obj1 = {
                name: legend[index],
                type: "bar",
                stack: legend[index],
                data: item,
                barWidth: "15%",
                itemStyle: {
                    normal: {
                        color: {
                            type: "linear",
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 0,
                                color: colorArr[index].start + "0.7)"
                            },
                                {
                                    offset: 0.5,
                                    color: colorArr[index].start + "0.3)"
                                },
                                {
                                    offset: 1,
                                    color: colorArr[index].end
                                }
                            ],
                            globalCoord: false
                        }
                    }
                }
            };
            // obj2 = {
            //     name: "",
            //     type: "bar",
            //     stack: legend[index],
            //     itemStyle: {
            //         barBorderRadius: '2px',
            //         normal: {
            //             color: colorArr[index].start + "1)"
            //         }
            //     },
            //     data: borderData
            // };
            seriesData.push(obj1);
            // seriesData.push(obj2);
        } else {
            var obj3 = {
                name: legend[index],
                type: "line",
                yAxisIndex: 1,
                smooth: false,
                symbol: "circle",
                symbolSize: 8,
                lineStyle: {
                    normal: {
                        width: 2
                    }
                },
                itemStyle: {
                    normal: {
                        color: colorArr[index].color,
                        borderColor: "#fff",
                        borderWidth: 1
                    }
                },
                data: item,
                label: {
                    normal: {
                        show: false
                    }
                }
            };
            seriesData.push(obj3);
        }
    });

    var option = {
        backgroundColor: "#fff",
        grid: {
            left: "3%",
            top: "12%",
            right: "3%",
            bottom: '2%',
            containLabel: true
        },
        legend: {
            show: true,
            // icon: "rect",
            itemWidth: 16,
            itemHeight: 9,
            right: "15%",
            top: "0%",
            textStyle: {
                color: "#848484"
            },
            data: legend
        },
        tooltip: {
            trigger: "axis",
            backgroundColor: 'rgba(18, 57, 60, .8)', //设置背景颜色
            textStyle: {
                color: '#fff'
            },
            borderColor: "rgba(18, 57, 60, .8)",
            axisPointer: {
                type: 'shadow',
                shadowStyle: {
                    color: 'rgba(0, 11, 34, .4)',
                }
            },
            formatter: function(params) {
                var str = "";
                for (var i = 0; i < params.length; i++) {
                    if (params[i].seriesName !== "") {
                        str +=
                            params[i].name +
                            ':' +
                            params[i].seriesName +
                            '-' +
                            params[i].value +
                            "<br/>";
                    }
                }
                return str;
            }
        },
        xAxis: [{
            type: "category",
            data: xData,
            axisPointer: {
                type: "shadow"
            },
            axisLabel: {
                interval: 0,
                align: 'center',
                textStyle: {
                    color: '#848484',
                    fontSize: 12
                }
            },
            axisLine: {
                lineStyle: {
                    color: '#1C82C5'
                }
            },
            axisTick: {
                show: false
            },
            splitLine: {
                show: false
            }
        }],
        yAxis: [{
            type: "value",
            // name: "亿千瓦时",
            // nameTextStyle: {
            //     color: normalColor,
            //     fontSize: 12
            // },
            // "min": 0,
            // "max": 50,
            axisLabel: {
                formatter: "{value}",
                textStyle: {
                    color: '#848484',
                    fontSize: 14
                }
            },
            axisLine: {
                lineStyle: {
                    color: '#1C82C5'
                }
            },
            axisTick: {
                show: false
            },
            splitLine: {
                show: true,
                lineStyle: {
                    type: "solid",
                    color: 'rgba(28, 130, 197, .3)'
                }
            }
        },
            {
                type: "value",
                // name: "%",
                // nameTextStyle: {
                //     color: normalColor,
                //     fontSize: 12
                // },
                // min: -100,
                // max: 100,
                axisLabel: {
                    formatter: "{value}",
                    textStyle: {
                        color: '#848484',
                        fontSize: 12
                    }
                },
                axisLine: {
                    lineStyle: {
                        color: '#1C82C5'
                    }
                },
                axisTick: {
                    show: false
                },
                splitLine: {
                    show: false,
                    lineStyle: {
                        type: "dashed",
                        color: "rgba(255,255,255,0.2)"
                    }
                }
            }
        ],
        series: seriesData
    };
    myeharts.clear();
    myeharts.setOption(option, true)
}