/* eslint-disable */
import Vue from 'vue'
import {
  Button,
  Toast,
  Field,
  NavBar,
  Tab,
  Tabs,
  ImagePreview,
  Image as VanImage,
  Divider,
  Uploader,
  Overlay,
  Icon,
  Popup,
  Empty,
  Search,
  List,
  Cell,
} from 'vant';

Vue.use(Button);
Vue.use(Toast);
Vue.use(Field);
Vue.use(NavBar);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(ImagePreview);
Vue.use(VanImage);
Vue.use(Divider);
Vue.use(Uploader);
Vue.use(Overlay);
Vue.use(Icon);
Vue.use(Popup);
Vue.use(Empty);
Vue.use(Search);
Vue.use(List);
Vue.use(Cell);