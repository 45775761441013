<template>
  <div class="order-page">
    <van-nav-bar class="title"
                 @click-left="handleBack"
                 left-arrow>
      <div slot="title">
        <span>详情</span>
      </div>
    </van-nav-bar>
    <div class="content">
      <div class="input-item">
        <div class="label">
          渠道名称
        </div>
        <div>{{ form.prdName }}</div>
      </div>
      <div class="input-item">
        <div class="label">
          客户名称
        </div>
        <div>{{ form.custName }}</div>
      </div>
      <div class="input-item">
        <div class="label">
          交货仓库
        </div>
        <div>{{ form.deliverywareCode }}</div>
      </div>
      <div class="input-item">
        <div class="label">
          仓库代码
        </div>
        <div>{{ form.warehouseCode }}</div>
      </div>
      <div class="input-item">
        <div class="label">
          邮编
        </div>
        <div>{{ form.zipcode }}</div>
      </div>
      <div class="input-item">
        <div class="label">
          体积
        </div>
        <div>{{ form.volume }}</div>
      </div>
      <div class="input-item">
        <div class="label">
          到仓时间
        </div>
        <div>{{ form.deliverywareDate }}</div>

      </div>
      <div class="input-item">
        <div class="label">
          报关件
        </div>
        <div>{{ form.ifCustoms == '0'?'是':'否' }}</div>
      </div>
      <div class="input-item">
        <div class="label">
          是否接受升舱
        </div>
        <div>{{ form.note == '0'?'是':'否' }}</div>
      </div>
      <div class="input-item">
        <div class="label">
          备注
        </div>
        <div>{{ form.remark }}</div>
      </div>

    </div>
    <div class="content-bottom">
      <v-btn class="submit"
             :loading="loading"
             :disabled="loading"
             @click="handleBack">
        返回
      </v-btn>
    </div>
  </div>
</template>
<script>
import {
  getChannelList
} from "@/api/api.js";
export default {
  data: () => ({
    id: "",
    valid: true,
    loading: false,
    form: {
      "custName": "", // 海关名称
      "deliverywareCode": "",
      "deliverywareDate": "",
      "ifCustoms": "", // 是否报关
      "note": "",
      "prdId": "",
      "prdName": "",
      "remark": "", // 备注
      "volume": "", // 体积
      "warehouseCode": "", // 仓库代码
      "zipcode": "",// 邮编
    },
    channelList: [],// 渠道列表
    editInfo: {},
  }),
  mounted () {
    this.id = this.$route.query.id;
    this.getChannelList();
    if (this.id) {
      let str = localStorage.getItem("forecastItem");
      if (str) {
        try {
          let form = JSON.parse(str);
          this.editInfo = form;
          for (const key in this.form) {
            if (Object.hasOwnProperty.call(this.form, key)) {
              this.form[key] = form[key];
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  },
  methods: {
    handleBack () {
      this.$router.back();
    },
    dayFormat (str) {
      str = str.slice(-2);
      return Number(str)
    },
    // 获取渠道
    getChannelList () {
      let param = {
      }
      getChannelList(param).then((res) => {
        let list = res.data || [];
        list = list.map(item => {
          return {
            text: item.prdName,
            value: item.prdName,
          }
        })
        this.channelList = list;
        this.loading = false;
      }).catch((err) => {
        console.log(err);
        let msg = err.msg || "网络异常"
        this.$toast(msg)
        this.loading = false;
      });
    },
  }
}
</script>
<style lang="scss" scoped>
.order-page {
  position: relative;
  // width:100%;
  height: 100vh;
  background-color: #f5f6fa;
  overflow: hidden;
  overflow-y: auto;
  padding-top: 2.8rem;
  font-size: 0.64rem;
  box-sizing: border-box;
  z-index: 1;

  > div:not(.van-popup, .van-nav-bar) {
    position: relative;
    z-index: 1;
  }
}

:deep(.v-input) {
  margin: 0;
  padding: 0;

  input {
    padding: 0.08rem 0;
    text-align: right;
  }

  .v-input__slot {
    &::after,
    &::before {
      display: none;
    }
  }
}

.v-radio {
  margin-left: 0.8rem;
}

:deep(.v-select__selections) {
  justify-content: flex-end;
}

:deep(.v-list-item__title) {
  font-size: 0.56rem;
}

:deep(.v-messages__message) {
  text-align: right;
}

.v-picker {
  font-size: 0.56rem;
}

// 标题
.title {
  text-align: left;

  :deep(.van-nav-bar__title) {
    margin: 0;
    margin-left: 3rem;
    font-weight: normal;
    font-size: 16px;

    > div {
      display: flex;
      align-items: center;
    }
  }

  :deep(.van-nav-bar__right:active) {
    opacity: 1;
  }

  .btn-list {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    span {
      padding: 0.08rem 0.4rem;
      color: dodgerblue;
      transition: all 0.3;

      &:active {
        opacity: 0.7;
      }
    }
  }
}

.content,
.content-bottom {
  width: 13.72rem;
  margin: 0 auto;
  background: #FFFFFF;
  border-radius: 0.48rem;
}

.content-bottom {
  background: none;
}

.required {
  font-size: 12px;
  color: red;
}

.input-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem 0;
  margin: 0 0.4rem 0 0.56rem;
  font-size: 0.56rem;
  color: #333333;
  line-height: 0.56rem;
  border-bottom: 1px solid #E8E8E8;

  .label {
    flex-shrink: 0;
    margin-right: 0.32rem;
  }
}

.order-page .v-btn.submit {
  display: block;
  margin: 1.2rem auto 0;
  width: 12rem;
  height: 1.6rem;
  line-height: 1.6rem;
  text-align: center;
  background-color: #3890FF;
  color: #fff;
  font-size: 0.64rem;
  border-radius: 4rem;
}

@media screen and (min-width: 500px) {
  .content,
  .content-bottom {
    margin: 0;
    margin-left: 0.64rem;
    width: 90%;
    max-width: 600px;
  }

  .content-bottom {
    position: fixed !important;
    bottom: 50px;
  }

  .input-item {
    padding: 20px 10px;
  }

  .order-page .v-btn.submit {
  }
}
</style>