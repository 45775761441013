import Vue from 'vue'
import Vuex from 'vuex'
import getters from "./getters"
import router from '@/router/index'
import {
  Toast
} from "vant";
import {
  getToken,
  setToken,
  removeToken,
  // setCookie
} from "@/utils/auth.js"
import {
  corpWxUserLogin,
  getAuthorizeUrl,
  getMenuList
} from "@/api/newApi";
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    token: getToken(),
    userInfo: null,
    selectCustomerForSave: null,
    selectChannelForSave: null,
    permissions:[],// 权限列表
  },
  getters,
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USERINFO(state, info) {
      state.userInfo = info;
    },
    SET_CUSTOMER_SAVE(state, info) {
      state.selectCustomerForSave = info;
    },
    SET_CHANNEL_SAVE(state, info) {
      state.selectChannelForSave = info;
    },
    SET_PERMISSION(state, info){
      state.permissions = info;
    },
  },
  actions: {
    setToken(context, token) {
      context.commit('SET_TOKEN', token)
      setToken(token);
    },
    setUserInfo(context, info) {
      context.commit('SET_USERINFO', info)
    },
    clearToken(context) {
      removeToken();
      context.commit('SET_TOKEN', "")
    },
    setSelectCustomerForSave(context, info) {
      context.commit('SET_CUSTOMER_SAVE', info)
    },
    setSelectChannelForSave(context, info) {
      context.commit('SET_CHANNEL_SAVE', info)
    },
    async login({
      dispatch
    }, code) {
      if (!code) {
        code = sessionStorage.getItem("code");
        if (!code) {
          // code还不存在的话 获取url获取code
          await dispatch("getAuthorizeUrl")
          return;
        }
      }
      let params = {
        corpWxCode: code,
      }
      await dispatch("clearToken");
      try {
        localStorage.setItem("failAuth", 0);
        sessionStorage.removeItem("loginFail");
        let res = await corpWxUserLogin(params);
        let token = res.data.token
        if (token) {
          await dispatch("setToken", token)
          router.replace("/home");
        }
      } catch (err) {
        sessionStorage.setItem("loginFail", true);
        if (err.code == 500 && err.msg && err.msg.indexOf("绑定") != -1) {
          sessionStorage.setItem("code", code);
          router.push("/login")
        } else if (err.code == 500 && err.msg && err.msg.indexOf("密码错误") != -1) {
          setTimeout(() => {
            this.getAuthorizeUrl();
          }, 100);
        }
        setTimeout(() => {
          Toast({
            message: err.msg || "登录异常!请刷新重试",
          })
        }, 0);
        console.log(err);
        throw err;
      }
    },
    async getAuthorizeUrl() {
      // let url = window.location.href;
      let url = "https://cwx.hubologistics.com";
      let params = {
        redirectUri: encodeURI(url),
        state: "forecast"
      }
      return getAuthorizeUrl(params).then((res) => {
        // window.location.href = "http://192.168.75.159:8084/?code=0xwcpBczST74ElyXzNsqHiF9OqJkfgPLB2rxiwEESoY"
        // window.location.href = res.data.authorizeUrl;
        // 发布时解开注释，用于本地调试
         window.location.href = res.data.authorizeUrl;
      }).catch((err) => {
        console.log(err);
      });
    },
    getMenuList({
      commit
    }) {
      return new Promise((resolve, reject) => {
        getMenuList()
          .then((res) => {
            commit('SET_PERMISSION', res.data.permissions)
            resolve()
          })
          .catch((err) => {
            console.log(err);
            reject(err)
          })
      })
    },
  }
})

export default store;