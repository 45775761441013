import Vue from 'vue';
// 权限指令
Vue.directive('hasPermission', {
  inserted(el, binding, vnode) {
    let that = vnode.context;
    let permissions = that.$store.getters.permissions;
    let flag = false;
    let val = binding.value;
    flag = permissions.find(item=>{
      return item === val;
    })
    if (!flag) {
      el.style.display = 'none'
      el.parentNode.removeChild(el);
    }else {
      // 如果是隐藏的则去除隐藏
      el.style.display === "none" && (el.style.display = '')
    }
  }
})