import axios from "./../axios.js";
import {
    newUrl,omsUrl
} from "./../baseUrl.js";

function newApi(data) {
    return axios.request({
        ...data,
        baseURL: newUrl
    })
}

function newApi2(data) {
    return axios.request({
        ...data,
        baseURL: omsUrl
    })
}


export function pageList(params) {
    return newApi({
        url: '/channel/page',
        method: 'get',
        params
    })
}

export function listSupplier(params) {
    return newApi({
        url: '/product/supplier/list',
        method: 'get',
        params
    })
}

export function getCurrentCycleChannelList(params) {
    return newApi({
        url: '/prdCycle/currentCycleChannelList',
        method: 'get',
        params
    })
}

export function getWarehouseInbound(params) {
    return newApi2({
        url: '/oms/warehouseInbound/page',
        method: 'get',
        params
    })
}

export function geyUnInboundGoodsByInboundCode(inboundCode) {
    return newApi2({
        url: '/oms/warehouseInbound/getUnInboundGoods/'+inboundCode,
        method: 'get'
    })
}

export function geyUnInboundGoodsById(id) {
    return newApi2({
        url: '/oms/warehouseInbound/'+id,
        method: 'get'
    })
}

export function batchInboundByInboundCode(inboundCode,data) {
    return newApi2({
        url: '/oms/warehouseInbound/goodsInbound/'+inboundCode,
        method: 'post',
        data:data
    })
}

export function reviewWarehouseInboundByCode(data) {
    return newApi2({
        url: '/oms/warehouseInbound/review',
        method: 'post',
        data:data
    })
}

export function QXGoodsInbound(inboundCode,data) {
    return newApi2({
        url: '/oms/warehouseInbound/cancelInbound/'+inboundCode,
        method: 'post',
        data:data
    })
}

export function getWarehouseLocationPage(params) {
    return newApi2({
        url: '/oms/warehouseLocation/page',
        method: 'get',
        params
    })
}

export function checkInbound(data) {
    return newApi2({
        url: '/oms/warehouseInbound/check',
        method: 'post',
        data:data
    })
}

export function getWarehouseLoadingOrder(params) {
    return newApi2({
        url: '/oms/warehouseLoadingOrder/page',
        method: 'get',
        params
    })
}

export function getWarehouseContainerById(id) {
    return newApi2({
        url: '/oms/warehouseLoadingOrder/'+id,
        method: 'get'
    })
}

export function getWarehouseLoadingOrderByContainerNo(containerNo,deliveryWarehouseName) {
    return newApi2({
        url: '/oms/warehouseLoadingOrder/getUnLoadedGoods/'+containerNo + "/" +deliveryWarehouseName,
        method: 'get'
    })
}

export function editWareHouseContainer(id,data) {
    return newApi2({
        url: '/oms/warehouseLoadingOrder/'+id,
        method: 'patch',
        data:data
    })
}

export function editWareHouseInbound(id,data) {
    return newApi2({
        url: '/oms/warehouseInbound/'+id,
        method: 'patch',
        data:data
    })
}

export function confirmWarehouseInbound(data) {
    return newApi2({
        url: '/oms/warehouseInbound/confirm',
        method: 'post',
        params:data
    })
}

export function setLocation(data) {
    return newApi2({
        url: '/oms/warehouseInbound/setLocation',
        method: 'post',
        data:data
    })
}