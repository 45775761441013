<template>
  <div class="list-item"
       :class="{ 'fail': settlesStatus == 2 }">
    <div class="info-title">
      <div>
        <span>{{ info.prdName || "" }}</span>
        <!-- <span class="tag" v-if="info.deliverywareCode">{{ info.deliverywareCode || "" }}</span> -->
      </div>
      <div class="status"
           v-if="info.status != null"
           :class="{ 'edit': info.status == '0' }">
        {{ statusMap[info.status] }}
      </div>
    </div>
    <div class="info-desc">
      <span>{{ info.custName }}</span>
      <span>填报人: {{ info.createBy }}</span>
    </div>
    <div class="info-content">
      <div class="info-item">
        <span>仓库代码</span>
        <span>{{ info.warehouseCode || "" }}</span>
      </div>
      <div class="info-item">
        <span>到仓日期</span>
        <span>{{ info.deliverywareDate || "" | getYMD }}</span>
      </div>
      <div class="info-item">
        <span>体 积</span>
        <span>{{ info.volume || "" }}</span>
      </div>
      <div class="info-item">
        <span>填报日期</span>
        <span>{{ info.createTime || "" | getYMD }}</span>
      </div>
    </div>
    <div class="bottom-btn">
      <template v-if="info.status!=4">
        <div class="btn"
             v-if="info.status == '1'"
             v-hasPermission="'product:prediction:cancelConfirm'"
             @click="$emit('confirmCancel',info)">
          确认取消
        </div>
        <div class="btn"
             v-if="info.status == '0'"
             @click="$emit('cancel',info)">
          取消
        </div>
        <div class="btn"
             v-if="info.status == '0'"
             @click="handleEdit">
          编辑
        </div>
      </template>
      <div class="btn"
           v-if="info.status == '0'"
           v-hasPermission="'product:prediction:confirm'"
           @click="$emit('confirmOrder',info)">
        确认
      </div>
      <div class="btn"
           @click="handleShowInfo">
        查看
      </div>
    </div>
  </div>
</template>

<script>
import { getYMD } from "@/utils/utils";
export default {
  props: {
    info: {
      type: Object,
      default: () => ({})
    },
    settlesStatus: {
      type: [Number, String],
      default: 0,
    }
  },
  filters: {
    getYMD
  },
  data: () => ({
    statusMap: {
      0: "预报",
      1: "取消",
      2: "截止",
      3: "已取消",
      4: "已确认",
    }
  }),
  methods: {
    handleShowInfo () {
      localStorage.setItem("forecastItem", JSON.stringify(this.info))
      this.$router.push({
        path: "/detail",
        query: {
          id: this.info.id,
        }
      })
    },
    handleEdit () {
      localStorage.setItem("forecastItem", JSON.stringify(this.info))
      this.$router.push({
        path: "/save",
        query: {
          id: this.info.id,
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.list-item {
  margin: 0 0.64rem 0.48rem;
  padding-bottom: 0.24rem;
  border-radius: 0.4rem;
  overflow: hidden;
  background-color: #fff;
  width: 13.72rem;
}

.info-content {
  display: flex;
  flex-wrap: wrap;
  padding-right: 0.64rem;
}

.info-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0.64rem 0.64rem 0.4rem;
  padding-right: 0.24rem;
  font-size: 0.64rem;
  font-family: Helvetica Neue, Helvetica,PF, PingFang SC, Hiragino Sans GB, Microsoft YaHei,
  '\5FAE\8F6F\96C5\9ED1', Arial, sans-serif;
  font-weight: 600;
  color: #333333;
  line-height: 0.76rem;
  background: linear-gradient(180deg, #e7ebff 0%, rgba(255, 255, 255, 0) 100%);

  span {
    position: relative;
  }

  span:first-child::before {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0.8rem;
    height: 0.24rem;
    border-radius: 0.24rem;
    background: #0029ff;
    opacity: 0.1;
  }

  span:last-child {
    font-weight: 400;
  }

  .tag {
    display: inline-block;
    margin-left: 0.64rem;
    margin-top: 0.12rem;
    padding: 0 4px;
    color: #1989fa;
    font-size: 0.48rem;
    line-height: 0.48rem;
    border-radius: 0.12rem;
    border: 1px solid #1989fa;
  }

  .status {
    width: 2rem;
    height: 1rem;
    border-radius: 0.16rem;
    font-size: 0.6rem;
    font-weight: 500;
    color: #3890FF;
    line-height: 1rem;
    color: #999999;
    text-align: center;

    &.edit {
      background: #e2e7ffee;
      color: #3890FF;
    }
  }
}

.info-desc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.64rem 0.48rem;
  font-size: 0.56rem;
  color: #888;
}

.info-item {
  display: flex;
  padding-left: 0.64rem;
  width: 50%;
  padding-bottom: 0.64rem;
  font-size: 0.56rem;
  font-family: Helvetica Neue, Helvetica,PF, PingFang SC, Hiragino Sans GB, Microsoft YaHei,
  '\5FAE\8F6F\96C5\9ED1', Arial, sans-serif;
  font-weight: 400;
  color: #777;
  line-height: 0.84rem;

  span:first-child {
    color: #222;
    width: 2.64rem;
  }
}

.price {
  color: #ee7945 !important;
}

// 卖家
.seller {
  .info-title {
    background: linear-gradient(
      180deg,
      #ffe8e6 0%,
      rgba(255, 255, 255, 0) 100%
    );

    span:first-child::before {
      background: #ff0000;
    }
  }
}

// 结算失败
.fail {
  .info-title {
    color: #666666;
    background: linear-gradient(
      180deg,
      #eeeeee 0%,
      rgba(255, 255, 255, 0) 100%
    );

    span:first-child::before {
      background: #666666;
    }
  }

  .info-item span:last-child {
    color: #666;
    text-align: right;
  }
}

.bottom-btn {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding: 0 0.64rem 0.24rem;
  padding-left: 0;
  &.btn:first-child {
    margin-left: 0;
  }
}

.btn {
  width: auto;
  margin: 0.64rem 0.24rem 0 0 ;
  padding: 0.14rem 0.8rem;
  font-size: 0.56rem;
  font-family: Helvetica Neue, Helvetica,PF, PingFang SC, Hiragino Sans GB, Microsoft YaHei,
  '\5FAE\8F6F\96C5\9ED1', Arial, sans-serif;
  font-weight: 400;
  color: #666666;
  line-height: 0.8rem;
  background: #ffffff;
  border-radius: 0.3rem;
  border: 1px solid #dadee1;
}
</style>