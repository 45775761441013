import Vue from 'vue'
import App from './App.vue'
import "./utils/vant.js";
import "./utils/permissions.js";
import router from './router/index.js'
import store from '@/store/index.js'
import '@/static/font.css'
import "@/common/js/adaptation.js";
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import Vant from 'vant'
import 'vant/lib/index.css'
import * as echarts from 'echarts'
import { Notify,Dialog,Toast  } from 'vant';
Vue.use(Vant);
Vue.use(Vuetify);
Vue.use(Notify);
Vue.use(Dialog);
Vue.use(Toast);
const opts = {
  lang: {
    current: 'zhHans',
  },
}
let vuetify = new Vuetify(opts);
Vue.config.productionTip = false
Vue.prototype.$echarts = echarts;
Vue.prototype.$Notify = Notify;


//解决ios输入框弹出的收起，底部空白问题
const windowHeight = window.innerHeight
Vue.directive('fixedInput', function (el) {
  el = el.querySelector("input") || el.querySelector("textarea")
  el.addEventListener('blur', function () {
    let windowFocusHeight = window.innerHeight
    if (windowHeight == windowFocusHeight) {
      return
    }
    let currentPosition;
    let speed = 1; //页面滚动距离
    currentPosition = document.documentElement.scrollTop || document.body.scrollTop;
    currentPosition -= speed;
    window.scrollTo(0, currentPosition); //页面向上滚动
    currentPosition += speed; //speed变量
    window.scrollTo(0, currentPosition); //页面向下滚动
  })
})

new Vue({
  render: h => h(App),
  router,
  vuetify,
  store: store,
}).$mount('#app')