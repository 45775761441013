<template>
  <v-app id="app">
    <keep-alive :include="['Housing','Container']">
      <router-view></router-view>
    </keep-alive>
  </v-app>
</template>

<script>
import {addPathVisit} from "./api/customer";
import {paths} from "./api/baseUrl";

export default {
  name: 'App',
  data: () => ({
  }),
  async created () {
  },
  mounted () {

  },
  methods: {

  },
    watch: {
        // 监听路由对象$route的变化
        '$route': {
            handler: function (to, from) {
                var data = "{\"path\":\""+to.path+"\"";
                if(JSON.stringify(paths).indexOf(data)!==-1){
                    // 路由发生变化时的处理逻辑
                    var formData1 = {
                        path:to.path,
                        clientType:1,
                    }
                    const formData = Object.keys(formData1)
                        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(formData1[key]))
                        .join('&');
                    addPathVisit(formData)
                        .then(resp => {

                        })
                }
            },
            // 设置为深度监听
            deep: true
        }
    },
}
</script>

<style lang="scss">
@import "@/common/css/base.scss";

/* 初始化 */
* {
  margin: 0;
  padding: 0;
}

.v-application .headline, .v-application .title,.v-application{
  font-family: Helvetica Neue, Helvetica,PF, PingFang SC, Hiragino Sans GB, Microsoft YaHei,
  '\5FAE\8F6F\96C5\9ED1', Arial, sans-serif!important;
}

.van-search{
  padding: 2px 6px;
}

span{
  /*font-family: Bahnschrift;*/
}

/* 基本样式 */
html,
body {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  // max-width: 750px;
  margin: 0 auto;
  box-sizing: border-box;
  font-family: Helvetica Neue, Helvetica,PF, PingFang SC, Hiragino Sans GB, Microsoft YaHei,
  '\5FAE\8F6F\96C5\9ED1', Arial, sans-serif;
}

/* 超出滚动 */
#app {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

[v-cloak] {
  display: none;
}

.van-popup {
  max-width: 500px;
}

/*.van-button--primary {*/
  /*background: linear-gradient(134deg,*/
      /*rgb(255, 147, 29) 0%,*/
      /*rgb(253, 121, 15) 28%,*/
      /*rgb(248, 88, 54) 100%);*/
/*}*/

.van-popup--center.van-popup--round {
  border-radius: 0.4rem !important;
}

.van-button,
.van-button--primary {
  border: none !important;
  border-radius: 2px !important;
}

.van-button--primary--disabled {
  opacity: 0.4;
}

.btn-area {
  text-align: center;

  .van-button {
    width: 6.8rem;
  }
}

// 通用弹窗
.common-makesure-pop {
  background: #fff;
  width: 11.8rem;
  border-radius: 0.8rem;
  padding: 0.84rem 1.44rem 0.96rem 1.44rem;

  .pop-box {
    .tit {
      font-size: 0;
      margin-bottom: 0.64rem;
      text-align: center;

      .first-tit {
        font-size: 0.72rem;
        color: #222;
        font-weight: bold;
      }

      .second-tit {
        font-size: 0.48rem;
        color: #666;
        margin-top: 0.08rem;
      }
    }

    .tips-box {
      line-height: 0.88rem;
      font-size: 0.56rem;
      color: #222;
      text-align: center;
      margin-bottom: -0.24rem;
      margin-bottom: 0.8rem;
    }

    .text-area {
      background: #f5f6fa;
      border-radius: 0.32rem;
      display: flex;
      align-items: center;
      padding: 0.48rem;
      justify-content: space-between;
      margin-bottom: 0.64rem;

      &:last-child {
        margin-bottom: 0;
      }

      .pop-input {
        font-size: 0.56rem;
        color: #333 !important;
        width: 100%;
        border: none !important;

        &.w100 {
          width: 100%;
        }
      }

      .uni-input-placeholder {
        color: #999 !important;
      }

      .pop-input-class {
        color: #999 !important;
      }

      .getcode {
        width: 2.56rem;
        height: 0.88rem;
        background: #fff;
        border-radius: 0.88rem;
        padding: 0;
        margin: 0;
        line-height: 0.88rem;
        font-size: 0.48rem;
        color: #222;

        &:after {
          border: 0.08rem solid #d9dfe2;
          border-radius: 1.76rem;
        }

        &[disabled] {
          color: #999;
        }
      }

      .input {
        width: 100%;
      }
    }

    .input-area {
      background: #f5f6fa;
      border-radius: 0.32rem;
      height: 1.76rem;
      display: flex;
      align-items: center;
      padding: 0 0.48rem 0 0.64rem;
      justify-content: space-between;
      margin-bottom: 0.64rem;

      &:last-child {
        margin-bottom: 0;
      }

      .pop-input {
        font-size: 0.56rem;
        color: #333 !important;
        width: 100%;
        border: none !important;

        &.w100 {
          width: 100%;
        }
      }

      .uni-input-placeholder {
        color: #999 !important;
      }

      .pop-input-class {
        color: #999 !important;
      }

      .getcode {
        width: 2.56rem;
        height: 0.88rem;
        background: #fff;
        border-radius: 0.88rem;
        padding: 0;
        margin: 0;
        line-height: 0.88rem;
        font-size: 0.48rem;
        color: #222;

        &:after {
          border: 0.08rem solid #d9dfe2;
          border-radius: 1.76rem;
        }

        &[disabled] {
          color: #999;
        }
      }

      .input {
        width: 100%;
      }
    }

    .btn-area {
      margin-top: 0.8rem;

      .u-button {
        margin-bottom: 0.64rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .type-change-area {
      font-size: 0.56rem;
      color: #fccd4a;
      text-align: center;
      margin-top: 0.84rem;
    }
  }
}

.common-makesure-pop {
  width: 100%;
  border-radius: 0;
  background-color: #fef8ed;
  padding: 0.8rem 0.72rem 1.2rem;

  .tips-txt {
    min-height: 4rem;
    max-height: 15rem;
    overflow-y: scroll;
    font-size: 0.6rem;
    line-height: 0.96rem;
    color: #333;
  }

  .btn-linear {
    background: #ef3d1a !important;
    margin-left: 1.76rem;
    margin-right: 1.76rem;
    height: 1.44rem;
    line-height: 1.44rem;
    font-size: 0.64rem !important;
  }

  .tabs {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 0.4rem 0.4rem;

    .tab-item {
      font-size: 0.64rem;
      font-weight: bold;
      color: #222222;

      &.active {
        font-size: 0.8rem;
        color: #f94026;
      }
    }
  }
}

// 弹窗样式 开始
.common-makesure-pop {
  background: linear-gradient(360deg, #d3e9fd 0%, #f7fbfc 100%);

  .btn-linear {
    background: #3890ff !important;
  }

  .tab-item.active {
    color: #3890ff;
  }
}

#app .v-card__title {
  font-size: .72rem;
}

#app .v-btn.v-size--default {
  font-size: .56rem;
}


.list-item {
  margin: 0 0.64rem 0.2rem;
  padding-bottom: 0rem;
  border-radius: 0.4rem;
  overflow: hidden;
  background-color: #fff;
  width: 13.72rem;
}

.info-content {
  display: flex;
  flex-wrap: wrap;
  padding-right: 0.64rem;
}

.info-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0.6rem 0.3rem 0.2rem;
  padding-right: 0.24rem;
  font-size: 0.62rem;
  font-family: Bahnschrift;
  font-weight: 600;
  color: #333333;
  line-height: 0.76rem;
  background: linear-gradient(180deg, #e7ebff 0%, rgba(255, 255, 255, 0) 100%);

  span {
    position: relative;
  }

  span:first-child::before {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0.8rem;
    height: 0rem;
    border-radius: 0.24rem;
    background: #0029ff;
    opacity: 0.1;
  }

  span:last-child {
    font-weight: 400;
  }

  .tag {
    display: inline-block;
    margin-left: 0.64rem;
    margin-top: 0.12rem;
    padding: 0 4px;
    color: #1989fa;
    font-size: 0.48rem;
    line-height: 0.48rem;
    border-radius: 0.12rem;
    border: 1px solid #1989fa;
  }

  .status {
    width: 2rem;
    height: 0.8rem;
    border-radius: 0.16rem;
    font-size: 0.4rem;
    font-weight: 500;
    color: #3890FF;
    line-height: 0.8rem;
    color: #999999;
    text-align: center;

    &.edit {
      background: #e2e7ffee;
      color: #3890FF;
    }
  }
}

.info-desc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.2rem 0.1rem;
  font-size: 12px;
  color: #888;
}

.info-item {
  display: flex;
  padding-left: 0.36rem;
  width: 50%;
  font-size: 0.45rem;
  font-family: Helvetica Neue, Helvetica,PF, PingFang SC, Hiragino Sans GB, Microsoft YaHei,
  '\5FAE\8F6F\96C5\9ED1', Arial, sans-serif;
  font-weight: 400;
  color: #777;
  line-height: 0.84rem;
  span{
    font-family: Bahnschrift;
  }

  span:first-child {
    color: #222;
    width: 2.2rem;
  }
}

.info-item-two {
  display: flex;
  padding-left: 0.36rem;
  width: 100%;
  font-size: 0.45rem;
  font-family: Helvetica Neue, Helvetica,PF, PingFang SC, Hiragino Sans GB, Microsoft YaHei,
  '\5FAE\8F6F\96C5\9ED1', Arial, sans-serif;
  font-weight: 400;
  color: #777;
  line-height: 0.84rem;
  span{
    font-family: Bahnschrift;
  }
  span:first-child {
    color: #222;
    width: 2.2rem;
  }
}


.price {
  color: #ee7945 !important;
}

// 卖家
.seller {
  .info-title {
    background: linear-gradient(
                    180deg,
                    #ffe8e6 0%,
                    rgba(255, 255, 255, 0) 100%
    );

    span:first-child::before {
      background: #ff0000;
    }
  }
}

// 结算失败
.fail {
  .info-title {
    color: #666666;
    background: linear-gradient(
                    180deg,
                    #eeeeee 0%,
                    rgba(255, 255, 255, 0) 100%
    );

    span:first-child::before {
      background: #666666;
    }
  }

  .info-item span:last-child {
    color: #666;
    text-align: right;
  }
}

.newTitle{
  width: 100%;height: 32px;background: linear-gradient(90deg, rgba(214, 109, 86, 0.13) 0%, rgba(255, 255, 255, 0) 99%);border-left: 4px solid #c84a40;
}

.newTitle .newContent{
  width: 100%;font-size: 14px;font-family: Bahnschrift;margin-left: 12px;padding-top: 6px;font-weight: 600;color: #c84a40;
  font-family: Helvetica Neue, Helvetica,PF, PingFang SC, Hiragino Sans GB, Microsoft YaHei,
  '\5FAE\8F6F\96C5\9ED1', Arial, sans-serif;
}

.globalButton{
  font-size: 12px;
  width: 100%;
  height: 28px;
  background: #2351c7;
  color: white;
  padding-left: 6px;
  padding-right: 6px;
  /*margin: 2px;*/
  display: flex;
  margin-top: 2px;
  margin-bottom: 2px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
}

.van-tag--large{
  font-size: 12px;
}
</style>
