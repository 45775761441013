import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
// import {
//   getToken
// } from "@/utils/auth.js"
import ROUTES from "./router.js"
Vue.use(Router)
//初始化
const router = new Router({
  base: "/",
  routes: ROUTES, //路由表
  mode: "history",
  encodeURI: false
});

router.beforeEach(async (to, from, next) => {
  let token = store.getters.token;
  let {
    code
  } = to.query;
  if (code) {
    // code 存在则尝试登录
    await store.dispatch("login", code).catch(err => {
      next()
      console.log(err);
    })
  } else if (!token && to.path === "/") {
    try {
      let token = to.token || localStorage.getItem("token") || "";
      await store.dispatch("setToken", token)
      let loginFail = sessionStorage.getItem("loginFail");
      if (!token && !code && !loginFail) {
        setTimeout(() => {
          store.dispatch("getAuthorizeUrl")
        }, 300);
      } else {
        next()
      }
    } catch (error) {
      next()
      console.log(error);
    }
  } else {
    try {
      await store.dispatch("getMenuList");
    } catch (error) {
      console.log(error);
    }
    next()
  }
});
// // 全局路由后置守卫
// router.afterEach((to, from) => {
// })

export default router