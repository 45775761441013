<template>
  <div class="channel-list">
    <van-search v-model="keyword" show-action placeholder="请输入搜索关键词" @clear="handleClear" @search="handleSearch">
      <template #action>
        <div @click="handleSearch">搜索</div>
      </template>
    </van-search>
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="handleLoad">
      <van-cell @click="handleClick(item)" v-for="item in list" :key="item.channelName" is-link :title="item.channelName || ''"
        clickable />
    </van-list>
  </div>
</template>
<script>
import {
  getChannelList,
} from "@/api/newApi.js";
import {getCurrentCycleChannelList} from "../../../api/channel";

export default {
  name: "channel-list",
  props: {
    userList: {
      type: Array,
      default: () => ([]),
    }
  },
  data: () => ({
    loading: false,
    list: [],
    finished: true,
    keyword: "",
    pageSize: 20,
    pageNo: 1,
  }),
  mounted () {
    this.id = this.$route.query.id;
    this.list = this.userList;
    if (this.list.length == 0) {
      this.getChannelList()
    }
  },
  methods: {
    handleBack () {
      this.$router.back();
    },
    dayFormat (str) {
      str = str.slice(-2);
      return Number(str)
    },
    handleClear () {
      this.keyword = "";
      this.handleSearch();
    },
    handleSearch () {
      this.pageNo = 1;
      this.getChannelList();
    },
    handleLoad () {

    },
    // 选中
    handleClick (item) {
      this.$store.dispatch("setSelectChannelForSave", {...item})
      sessionStorage.setItem("selectChannelForSave", JSON.stringify(item))
      this.$emit("change", item)
    },
    // 获取用户
    getChannelList () {
      let param = {
        name: this.keyword,
        prdName:this.keyword,
      }
      this.loading = true;
        getCurrentCycleChannelList(param).then((res) => {
        let list = res.data || [];
        this.list = list;
        this.finished = true;
        this.loading = false;
      }).catch((err) => {
        console.log(err);
        let msg = err.msg || "网络异常"
        this.$toast(msg)
        this.finished = true;
        this.loading = false;
      });
    },
  }
}
</script>
<style lang="scss" scoped>
.channel-list {
  position: relative;
  background: #fff;
  height: 100%;
  overflow-y: auto;
  padding-top: 2rem;
}

.van-search {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.van-list {
  height: 100%;
  overflow-y: auto;
}
</style>