<template>
  <div class="login-page">
    <!-- <img class="login-bg" src="@/assets/login-bg.png" alt=""> -->
    <div class="content">
      <div class="title">绑定账号</div>
      <div class="input-part">
        <div class="input-title">账号</div>
        <van-field v-model.trim="username" v-fixedInput placeholder="请输入账号" maxlength="30" />
      </div>
      <div class="input-part">
        <div class="input-title">登录密码</div>
        <van-field v-model.trim="password" type="password" v-fixedInput placeholder="请输入登录密码" maxlength="30" />
      </div>
      <van-button class="submit-btn" @click="handleSubmit" :loading="loading" type="info">登录</van-button>
    </div>
  </div>
</template>

<script>
import { corpWxUserBind } from "@/api/newApi";
export default {
  name: "LoginPage",
  data () {
    return {
      username: "",
      password: "",
      loading: false,
    }
  },
  methods: {
    handleSubmit () {
      let msg = "";
      msg = !this.username ? "请输入账号" : msg;
      msg = !this.password ? "请输入密码" : msg;
      if (msg) {
        this.$toast(msg)
        return;
      }
      let corpWxCode = sessionStorage.getItem("code");
      localStorage.setItem("userName",this.username);
      if(!corpWxCode) {
        this.$router.push("/")
        return;
      }
      this.loading = true;
      let params = {
        username: this.username,
        password: this.password,
        corpWxCode,
      }
      corpWxUserBind(params).then((res) => {
        this.$store.dispatch("login", corpWxCode);
      }).catch((err) => {
        this.$toast(err.msg || "绑定失败!请重试")
        console.log(err);
      }).finally(() => {
        this.loading = false;
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.login-page {
  width: 100%;
  max-width: 600px;
  margin: 10% auto 0;
}

.login-bg {
  width: 100%;
  height: auto;
  // position: absolute;
  // top:0px;
  // left: 0px;
  // right:0px
}

.content {
  padding: 0px 1.2rem;
  // margin-top: 4.4rem;
}

.title {
  font-size: 1.12rem;
  font-family: Helvetica Neue, Helvetica,PF, PingFang SC, Hiragino Sans GB, Microsoft YaHei,
  '\5FAE\8F6F\96C5\9ED1', Arial, sans-serif;
  line-height: 1.32rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

.input-part {
  border-bottom: 1px solid #CED4F0;
  margin-bottom: 1.36rem;

  .input-title {
    font-size: 0.64rem;
    line-height: 0.76rem;
    font-weight: bold;
  }

  .van-field {
    padding: 0.4rem 0;
  }

  ::v-deep .van-field__control {
    font-size: 0.64rem;
  }
}

.submit-btn {
  display: block;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  margin-top: 0.64rem;
  background-color: #557AFF;
  font-size: 0.72rem;
  border-radius: 0.2rem;
  border: none;
  height: 1.92rem;
}
</style>