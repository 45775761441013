import axios from "axios";
import {
  Toast
} from 'vant'
// import {
//   baseUrl,
//   newUrl
// } from "./baseUrl.js";
import store from "@/store/index.js"
import router from "@/router/index.js";
// import Router from '@/router'
//带三方类库
// import qs from 'qs'
let options = {
  timeout: 300000,
  baseURL: '/',
  withCredentials: false,
}
const axiosInstance = axios.create(options);

/**
 * 设置请求数据参数传递的格式，默认是json格式，但是在登录校验中后台一般设置请求格式：x-www-form-urlencoded(name=xxx,age=xxx)
 * 看服务器要求什么格式
 */
// axios.defaults.headers['Content-Type']='application/x-www-form-urlencoded' //声明请求格式
// axios.defaults.transformRequest=data=>qs.stringify(data)  //qs是第三方库，转换为x-www-form-urlencoded
/**
 * 设置请求拦截器：----在项目中发请求（请求没有发出去）可以做一些事情
 * 客户端->[请求拦截器]->服务器端
 * token校验（JWT）：接收到服务器的token,存储到vuex/本地存储中，每次向服务器发送请求，我们应该把token带上
 * config :发起请求的请求配置项
 */
axiosInstance.interceptors.request.use(config => {
  let token = localStorage.getItem('token')
  if (token) {
    config.headers.Authorization = "Bearer "+ token
    config.headers.token = "Bearer "+ token
  }
  return config
}, error => {
  return Promise.reject(error)
})
/**
 * 设置响应拦截器
 * 服务器端返回信息->[响应拦截器]->客户端js获取到信息
 * response中包含属性：
 * data：相应数据,status:响应状态码,statusText：响应状态信息,headers：响应头,config：响应提供的配置信息,request
 */
const noNeedDealCodeArr = [];
let tempTimeout
let loginFailNum = 0;
axiosInstance.interceptors.response.use(response => {
  const res = response.data
  // 处理文件上传
  if (res.status == 200 || res.Status) {
    return response
  }
  if (res.code !== 200) { // 服务端返回的code不等于0，则reject()
    if (res.code === 401) {
      clearTimeout(tempTimeout)
      let loginFail = sessionStorage.getItem("loginFail");
      let failAuthNum = localStorage.getItem("failAuth") || 0;
      failAuthNum = Number(failAuthNum) || 0;
      localStorage.setItem("failAuth", ++failAuthNum);
        // location.href = '/login';
      if (!loginFail) {
        tempTimeout = setTimeout(() => {
          store.dispatch("login");
        }, 500)
      }
    } else if (noNeedDealCodeArr.indexOf(res.code) != -1) {
      //具体前台拦截错误
    } else if (res.code === 2) {
      Toast({
        message: '系统异常',
      })
    } else {
      Toast({
        message: res.msg ? res.msg : res.errMsg,
      })
    }
    return Promise.reject(response.data)
  }
  return response.data

}, error => {
  let {
    response
  } = error
  // 如果有返回结果
  if (response) {
    switch (response.status) {
      case 404:
        //进行错误跳转之类
        break;
    }
    return Promise.reject(response.data)
  } else {
    // //服务器没有返回结果 分两种情况 断网  服务器崩了
    // if (!window.navigator.onLine) {
    //   //断网处理：跳转到断网页面
    //   return
    // }
    return Promise.reject(error)
  }
})
export default axiosInstance;