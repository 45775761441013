<template>
  <div class="customer-page">
    <van-nav-bar class="title" @click-left="handleBack" left-arrow>
      <div slot="title">
        <span>选择客户</span>
      </div>
    </van-nav-bar>
    <div class="content">
      <customer-list @change="handleSelect" />
    </div>
  </div>
</template>
<script>
import CustomerList from "./components/customer-list.vue";
export default {
  name: "customer",
  components: {
    CustomerList,
  },
  data: () => ({
    loading: false,
    list: [],
  }),
  mounted () {
    this.id = this.$route.query.id;

  },
  methods: {
    handleSelect () {
      this.$router.back();
    },
    handleBack () {
      this.$router.back();
    },
    dayFormat (str) {
      str = str.slice(-2);
      return Number(str)
    },
  }
}
</script>
<style lang="scss" scoped>
.customer-page {
  position: relative;
  // width:100%;
  height: 100vh;
  background-color: #f5f6fa;
  overflow: hidden;
  overflow-y: auto;
  padding-top: 1.84rem;
  font-size: 0.64rem;
  box-sizing: border-box;
  z-index: 1;

  >div:not(.van-popup, .van-nav-bar) {
    position: relative;
    z-index: 1;
  }
}

.content {
  height: 100%;
}

:deep(.v-input) {
  margin: 0;
  padding: 0;

  input {
    padding: .08rem 0;
    text-align: right;
  }

  .v-input__slot {

    &::after,
    &::before {
      display: none;
    }
  }
}

.v-radio {
  margin-left: .8rem;
}

:deep(.v-select__selections) {
  justify-content: flex-end;
}

:deep(.v-list-item__title) {
  font-size: .56rem;
}

:deep(.v-messages__message) {
  text-align: right;
}

.v-picker {
  font-size: .56rem;
}

// 标题
.title {
  text-align: left;

  :deep(.van-nav-bar__title) {
    margin: 0;
    margin-left: 3rem;
    font-weight: normal;
    font-size: 16px;

    >div {
      display: flex;
      align-items: center;
    }
  }

  :deep(.van-nav-bar__right:active) {
    opacity: 1;
  }

  .btn-list {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    span {
      padding: .08rem .4rem;
      color: dodgerblue;
      transition: all .3;

      &:active {
        opacity: 0.7;
      }
    }
  }
}


@media screen and (min-width: 500px) {

  .content,
  .content-bottom {
    margin: 0;
    margin-left: .64rem;
    width: 90%;
    max-width: 600px;
  }

  .content-bottom {
    position: fixed !important;
    bottom: 50px;
  }

  .input-item {
    padding: 20px 10px;
  }

  .order-page .v-btn.submit {}
}
</style>